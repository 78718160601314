module.exports = [{
  plugin: require('/vercel/317f3503/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [{
      "resolve": "/vercel/317f3503/node_modules/gatsby-remark-images",
      "id": "3626c0e0-58d4-5046-b0e5-cb7731a052f2",
      "name": "gatsby-remark-images",
      "version": "3.1.25",
      "pluginOptions": {
        "plugins": []
      },
      "nodeAPIs": [],
      "browserAPIs": ["onRouteUpdate"],
      "ssrAPIs": ["onRenderBody"]
    }],
    "extensions": [".mdx", ".md"],
    "defaultLayouts": {
      "default": "/vercel/317f3503/src/components/layouts/index.js"
    },
    "gatsbyRemarkPlugins": [{
      "resolve": "gatsby-remark-images",
      "options": {
        "maxWidth": 960,
        "sizeByPixelDensity": true
      }
    }]
  }
}, {
  plugin: require('/vercel/317f3503/node_modules/gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/vercel/317f3503/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
  options: {
    "plugins": [],
    "name": "ds-kit",
    "short_name": "ds-kit",
    "start_url": "/",
    "background_color": "#663399",
    "theme_color": "#663399",
    "display": "minimal-ui",
    "icon": "src/images/ds-kit-icon.png",
    "include_favicon": true
  }
}];