// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-components-layouts-pattern-wrapper-layout-js": function componentSrcComponentsLayoutsPatternWrapperLayoutJs() {
    return import("/vercel/317f3503/src/components/layouts/pattern-wrapper-layout.js"
    /* webpackChunkName: "component---src-components-layouts-pattern-wrapper-layout-js" */
    );
  },
  "component---src-components-layouts-package-layout-js": function componentSrcComponentsLayoutsPackageLayoutJs() {
    return import("/vercel/317f3503/src/components/layouts/package-layout.js"
    /* webpackChunkName: "component---src-components-layouts-package-layout-js" */
    );
  },
  "component---src-pages-404-js": function componentSrcPages404Js() {
    return import("/vercel/317f3503/src/pages/404.js"
    /* webpackChunkName: "component---src-pages-404-js" */
    );
  },
  "component---src-pages-index-js": function componentSrcPagesIndexJs() {
    return import("/vercel/317f3503/src/pages/index.js"
    /* webpackChunkName: "component---src-pages-index-js" */
    );
  },
  "component---src-pages-patterns-js": function componentSrcPagesPatternsJs() {
    return import("/vercel/317f3503/src/pages/patterns.js"
    /* webpackChunkName: "component---src-pages-patterns-js" */
    );
  },
  "component---src-pages-patterns-blog-post-listing-js": function componentSrcPagesPatternsBlogPostListingJs() {
    return import("/vercel/317f3503/src/pages/patterns/blog-post-listing.js"
    /* webpackChunkName: "component---src-pages-patterns-blog-post-listing-js" */
    );
  },
  "component---src-pages-patterns-blog-post-js": function componentSrcPagesPatternsBlogPostJs() {
    return import("/vercel/317f3503/src/pages/patterns/blog-post.js"
    /* webpackChunkName: "component---src-pages-patterns-blog-post-js" */
    );
  },
  "component---src-pages-patterns-callout-js": function componentSrcPagesPatternsCalloutJs() {
    return import("/vercel/317f3503/src/pages/patterns/callout.js"
    /* webpackChunkName: "component---src-pages-patterns-callout-js" */
    );
  },
  "component---src-pages-patterns-controls-bar-example-1-js": function componentSrcPagesPatternsControlsBarExample1Js() {
    return import("/vercel/317f3503/src/pages/patterns/controls-bar-example-1.js"
    /* webpackChunkName: "component---src-pages-patterns-controls-bar-example-1-js" */
    );
  },
  "component---src-pages-patterns-controls-bar-example-2-js": function componentSrcPagesPatternsControlsBarExample2Js() {
    return import("/vercel/317f3503/src/pages/patterns/controls-bar-example-2.js"
    /* webpackChunkName: "component---src-pages-patterns-controls-bar-example-2-js" */
    );
  },
  "component---src-pages-patterns-controls-bar-example-3-js": function componentSrcPagesPatternsControlsBarExample3Js() {
    return import("/vercel/317f3503/src/pages/patterns/controls-bar-example-3.js"
    /* webpackChunkName: "component---src-pages-patterns-controls-bar-example-3-js" */
    );
  },
  "component---src-pages-patterns-controls-bar-example-4-js": function componentSrcPagesPatternsControlsBarExample4Js() {
    return import("/vercel/317f3503/src/pages/patterns/controls-bar-example-4.js"
    /* webpackChunkName: "component---src-pages-patterns-controls-bar-example-4-js" */
    );
  },
  "component---src-pages-patterns-data-source-page-js": function componentSrcPagesPatternsDataSourcePageJs() {
    return import("/vercel/317f3503/src/pages/patterns/data-source-page.js"
    /* webpackChunkName: "component---src-pages-patterns-data-source-page-js" */
    );
  },
  "component---src-pages-patterns-data-story-listing-js": function componentSrcPagesPatternsDataStoryListingJs() {
    return import("/vercel/317f3503/src/pages/patterns/data-story-listing.js"
    /* webpackChunkName: "component---src-pages-patterns-data-story-listing-js" */
    );
  },
  "component---src-pages-patterns-essay-header-example-1-js": function componentSrcPagesPatternsEssayHeaderExample1Js() {
    return import("/vercel/317f3503/src/pages/patterns/essay-header-example-1.js"
    /* webpackChunkName: "component---src-pages-patterns-essay-header-example-1-js" */
    );
  },
  "component---src-pages-patterns-essay-header-example-2-js": function componentSrcPagesPatternsEssayHeaderExample2Js() {
    return import("/vercel/317f3503/src/pages/patterns/essay-header-example-2.js"
    /* webpackChunkName: "component---src-pages-patterns-essay-header-example-2-js" */
    );
  },
  "component---src-pages-patterns-featured-topic-js": function componentSrcPagesPatternsFeaturedTopicJs() {
    return import("/vercel/317f3503/src/pages/patterns/featured-topic.js"
    /* webpackChunkName: "component---src-pages-patterns-featured-topic-js" */
    );
  },
  "component---src-pages-patterns-home-banner-js": function componentSrcPagesPatternsHomeBannerJs() {
    return import("/vercel/317f3503/src/pages/patterns/home-banner.js"
    /* webpackChunkName: "component---src-pages-patterns-home-banner-js" */
    );
  },
  "component---src-pages-patterns-home-page-js": function componentSrcPagesPatternsHomePageJs() {
    return import("/vercel/317f3503/src/pages/patterns/home-page.js"
    /* webpackChunkName: "component---src-pages-patterns-home-page-js" */
    );
  },
  "component---src-pages-patterns-immersive-story-cover-js": function componentSrcPagesPatternsImmersiveStoryCoverJs() {
    return import("/vercel/317f3503/src/pages/patterns/immersive-story-cover.js"
    /* webpackChunkName: "component---src-pages-patterns-immersive-story-cover-js" */
    );
  },
  "component---src-pages-patterns-immersive-story-drawer-js": function componentSrcPagesPatternsImmersiveStoryDrawerJs() {
    return import("/vercel/317f3503/src/pages/patterns/immersive-story-drawer.js"
    /* webpackChunkName: "component---src-pages-patterns-immersive-story-drawer-js" */
    );
  },
  "component---src-pages-patterns-immersive-story-navigation-js": function componentSrcPagesPatternsImmersiveStoryNavigationJs() {
    return import("/vercel/317f3503/src/pages/patterns/immersive-story-navigation.js"
    /* webpackChunkName: "component---src-pages-patterns-immersive-story-navigation-js" */
    );
  },
  "component---src-pages-patterns-immersive-story-toolbar-js": function componentSrcPagesPatternsImmersiveStoryToolbarJs() {
    return import("/vercel/317f3503/src/pages/patterns/immersive-story-toolbar.js"
    /* webpackChunkName: "component---src-pages-patterns-immersive-story-toolbar-js" */
    );
  },
  "component---src-pages-patterns-listing-page-header-js": function componentSrcPagesPatternsListingPageHeaderJs() {
    return import("/vercel/317f3503/src/pages/patterns/listing-page-header.js"
    /* webpackChunkName: "component---src-pages-patterns-listing-page-header-js" */
    );
  },
  "component---src-pages-patterns-modal-with-controls-js": function componentSrcPagesPatternsModalWithControlsJs() {
    return import("/vercel/317f3503/src/pages/patterns/modal-with-controls.js"
    /* webpackChunkName: "component---src-pages-patterns-modal-with-controls-js" */
    );
  },
  "component---src-pages-patterns-newsletter-section-js": function componentSrcPagesPatternsNewsletterSectionJs() {
    return import("/vercel/317f3503/src/pages/patterns/newsletter-section.js"
    /* webpackChunkName: "component---src-pages-patterns-newsletter-section-js" */
    );
  },
  "component---src-pages-patterns-secondary-navigation-js": function componentSrcPagesPatternsSecondaryNavigationJs() {
    return import("/vercel/317f3503/src/pages/patterns/secondary-navigation.js"
    /* webpackChunkName: "component---src-pages-patterns-secondary-navigation-js" */
    );
  },
  "component---src-pages-patterns-services-section-js": function componentSrcPagesPatternsServicesSectionJs() {
    return import("/vercel/317f3503/src/pages/patterns/services-section.js"
    /* webpackChunkName: "component---src-pages-patterns-services-section-js" */
    );
  },
  "component---src-pages-patterns-simple-essay-js": function componentSrcPagesPatternsSimpleEssayJs() {
    return import("/vercel/317f3503/src/pages/patterns/simple-essay.js"
    /* webpackChunkName: "component---src-pages-patterns-simple-essay-js" */
    );
  },
  "component---src-pages-patterns-site-footer-js": function componentSrcPagesPatternsSiteFooterJs() {
    return import("/vercel/317f3503/src/pages/patterns/site-footer.js"
    /* webpackChunkName: "component---src-pages-patterns-site-footer-js" */
    );
  },
  "component---src-pages-patterns-site-navigation-js": function componentSrcPagesPatternsSiteNavigationJs() {
    return import("/vercel/317f3503/src/pages/patterns/site-navigation.js"
    /* webpackChunkName: "component---src-pages-patterns-site-navigation-js" */
    );
  },
  "component---src-pages-patterns-topic-listing-js": function componentSrcPagesPatternsTopicListingJs() {
    return import("/vercel/317f3503/src/pages/patterns/topic-listing.js"
    /* webpackChunkName: "component---src-pages-patterns-topic-listing-js" */
    );
  },
  "component---src-pages-patterns-topic-js": function componentSrcPagesPatternsTopicJs() {
    return import("/vercel/317f3503/src/pages/patterns/topic.js"
    /* webpackChunkName: "component---src-pages-patterns-topic-js" */
    );
  },
  "component---src-pages-tools-js": function componentSrcPagesToolsJs() {
    return import("/vercel/317f3503/src/pages/tools.js"
    /* webpackChunkName: "component---src-pages-tools-js" */
    );
  },
  "component---src-pages-chart-colors-md": function componentSrcPagesChartColorsMd() {
    return import("/vercel/317f3503/src/pages/chart-colors.md"
    /* webpackChunkName: "component---src-pages-chart-colors-md" */
    );
  },
  "component---src-pages-colors-md": function componentSrcPagesColorsMd() {
    return import("/vercel/317f3503/src/pages/colors.md"
    /* webpackChunkName: "component---src-pages-colors-md" */
    );
  },
  "component---src-pages-icons-md": function componentSrcPagesIconsMd() {
    return import("/vercel/317f3503/src/pages/icons.md"
    /* webpackChunkName: "component---src-pages-icons-md" */
    );
  },
  "component---src-pages-getting-started-md": function componentSrcPagesGettingStartedMd() {
    return import("/vercel/317f3503/src/pages/getting-started.md"
    /* webpackChunkName: "component---src-pages-getting-started-md" */
    );
  },
  "component---src-pages-something-md": function componentSrcPagesSomethingMd() {
    return import("/vercel/317f3503/src/pages/something.md"
    /* webpackChunkName: "component---src-pages-something-md" */
    );
  },
  "component---src-pages-templates-md": function componentSrcPagesTemplatesMd() {
    return import("/vercel/317f3503/src/pages/templates.md"
    /* webpackChunkName: "component---src-pages-templates-md" */
    );
  },
  "component---src-pages-tools-sprite-sheet-checker-md": function componentSrcPagesToolsSpriteSheetCheckerMd() {
    return import("/vercel/317f3503/src/pages/tools/sprite-sheet-checker.md"
    /* webpackChunkName: "component---src-pages-tools-sprite-sheet-checker-md" */
    );
  },
  "component---src-pages-typography-md": function componentSrcPagesTypographyMd() {
    return import("/vercel/317f3503/src/pages/typography.md"
    /* webpackChunkName: "component---src-pages-typography-md" */
    );
  }
};